@tailwind base;
@tailwind components;

.sendbird-chat-header,
.sendbird-chat-header .sendbird-channel-header__right-icon,
.sendbird-chat-header__right,
.sendbird-message-input--attach {
  display: none !important;
}

.sendbird-conversation__not-initialized.sendbird-place-holder {
  visibility: hidden;
}

.sendbird-theme--light .sendbird-conversation {
  border: 0;
}

.readonly .sendbird-conversation__footer,
.readonly .sendbird-user-message__more,
.readonly .sendbird-context-menu {
  display: none !important;
}

.sendbird-message-input .sendbird-message-input--textarea {
  overflow: auto !important;
}

.sendbird-conversation__messages
  .sendbird-conversation__scroll-container
  .sendbird-conversation__messages-padding {
  padding-bottom: 48px;
  padding-left: 12px;
  padding-right: 12px;
}

.sendbird-theme--light .sendbird-conversation__footer {
  padding: 0 !important;
}

.sendbird-theme--light
  .sendbird-message-input-wrapper
  .sendbird-message-input-wrapper__message-input {
  padding: 0 0.5rem;
  z-index: 1;
}

.sendbird-theme--light
  .sendbird-message-input
  .sendbird-message-input--textarea {
  @apply border-gray-100 bg-gray-100 transition;
}

.sendbird-theme--light
  .sendbird-message-input
  .sendbird-message-input--textarea:hover,
.sendbird-theme--light
  .sendbird-message-input
  .sendbird-message-input--textarea:focus {
  @apply border-gray-200 bg-gray-50;
  box-shadow: none;
  outline: 0;
}

.w-md-editor-preview ul {
  list-style-type: disc;
}
.w-md-editor-preview ol {
  list-style-type: decimal;
}

.prose a[href^='http'] {
  @apply break-all;
}

.prose pre {
  @apply whitespace-break-spaces;
}

@tailwind utilities;
